#modalContent{
	> .silver-modal-dialog{
		margin: 0;
		width: 1174px!important;
		> .silver-modal-content{
			border:0;
			border-radius: 0px;
			> .silver-close-modal{
				opacity: 1;
				text-shadow:none;
				float: none;
				position: absolute;
				top: 101px;
				right: 107px;
				width: 14px;
				height: 14px;
				background: url('../images/popup__close.png') no-repeat;
				cursor: pointer;
				z-index: 10;
			}
			> .silver-modal-body{
				padding: 0px;
				width: 100%!important;
			}
		}
	}
}

.silver-modal-body{
	.alert{
		display: none;
	}
	#webform-client-form-1{
		padding: 53px 86px 60px 86px;
		input[type="text"],
		input[type="email"]{
			width: 100%;
			height: 50px;
			background-color: #dce1ec;
			border-radius: 25px;
			color: #384359;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 400;
			border:0;
			box-shadow: none;
			padding: 0 0 0 30px;
			@include placeholder(){
				color: #384359;
				font-family: $MontserratAlter;
				font-size: 14px;
				font-weight: 400;
			}
		}
		select{
			width: 100%;
			height: 50px;
			border-radius: 25px;
			color: white;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 400;
			-webkit-appearance: none;
			/* for FF */
			-moz-appearance: none;
			text-indent: 0.01px; 
			text-overflow: '';
			/* for IE */
			-ms-appearance: none;
			appearance: none!important;
			background-image: url('../images/form__select--dropdown.png');
			background-position: right 29px center;
			background-repeat: no-repeat;
			background-color: #384359;
			border:0;
			box-shadow: none;
			padding: 0 0 0 30px;
			&::-ms-expand {
			  display: none;
			}
		}
		textarea{
			width: 100%;
			height: 100px;
			background-color: #dce1ec;
			border-radius: 25px;
			color: #384359;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 400;
			border:0;
			box-shadow: none;
			text-align: center;
			padding: 40px 0 0 0;
			resize:none;
			@include placeholder(){
				color: #384359;
				font-family: $MontserratAlter;
				font-size: 14px;
				font-weight: 400;
			}
			&:focus{
				text-align: left;
				padding: 15px 0 0 30px;
			}
		}
		.error{
			border: 1px solid #ef4665!important;
		}
		.form-actions{
			text-align: center;
		}
		input[type="submit"]{
			border:0;
			padding: 16px 40px 15px 39px;
			background-color: #eba327;
			border-radius: 25px;
			color: white;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
		fieldset{
			border:0;
			background-color: transparent;
			box-shadow: none;
			border-radius: 0;
			margin: 0;
			> .panel-body{
				padding: 0;
			}
		}
		.form-item{
			margin-bottom: 30px;
		}
		.webform-component--title{
			font-family: $Lora;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 26px;
			text-align: center;
			.w__title{
				font-size: 20px;
			}
			.w__subtitle{
				font-size: 40px;
			}
		}

		.webform-component-email,
		.webform-component-textfield,
		.webform-component-select{
			width: 300px;
		}

		.webform-component--flex-wrap{
			> .panel-body{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				> *{
					&:not(:nth-child(3n+3)):not(:last-child){
						margin-right: 50px;
					}
				}
			}
		}

	}
}

@include respond-to('medium'){
	#modalContent{
		> .silver-modal-dialog{
			width: 768px !important;
			>.silver-modal-content{
				>.silver-close-modal{
					top: 10px;
					right: 10px;
				}
			}
		}
	}
	.silver-modal-body{
		#webform-client-form-1{
			padding: 51px 70px 60px 70px;
			.webform-component--flex-wrap{
				>.panel-body{
					justify-content:space-between;
					&:before,
					&:after{
						content: none;
					}
					> *{
						&:not(:nth-child(3n+3)):not(:last-child){
							margin-right: 0px;
						}
					}
				}
			}
			.webform-component--title{
				margin-bottom: 18px;
				.w__subtitle{
					font-size: 35px;
				}
			} 
		} 
	} 
}

@include respond-to('small'){
	#modalContent{
		> .silver-modal-dialog{
			width: 320px!important;
			>.silver-modal-content{
				>.silver-close-modal{
					top: 10px;
					right: 10px;
				}
			}
		}
	}
	.silver-modal-body{
		#webform-client-form-1{
			padding: 51px 10px 60px 10px;
			.webform-component--flex-wrap{
				>.panel-body{
					flex-direction:column;
					align-items:center;
					justify-content:flex-start;
					&:before,
					&:after{
						content: none;
					}
					> *{
						&:not(:nth-child(3n+3)):not(:last-child){
							margin-right: 0px;
						}
					}
				}
			}
			.webform-component--title{
				margin-bottom: 18px;
				.w__subtitle{
					font-size: 33px;
				}
			} 
			.form-item{
				margin-bottom: 10px;
			}
			.webform-component-textarea{
				margin-bottom: 31px;
			}
			.webform-component--title{
				margin-bottom: 18px;
				.w__subtitle{
					font-size: 33px;
				}
			}
		} 
	} 
}