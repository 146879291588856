body.node-type-m-our-values{

	.node-m-our-values{
		
		.big-image{
			margin-bottom: 62px;
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 90px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 90px;
				}
			}
		}

	}

	.paragraphs-item-pb-circle-text-set{
		border-bottom: 0;
		margin-bottom: 62px;
		padding-bottom: 0px;
	}

	.paragraphs-item-pb-circle-text{
		.field-name-field-description{
			line-height: 1.34;
			font-size: 15px;
			padding: 0px 10px;
		}
		.field-name-field-link-circle {
			display: none;
		}
	} 

	@include respond-to('medium'){
		.node-m-our-values{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
			.paragraphs-item-pb-slider-gallery{
				margin-bottom: 0px;
			}
			.paragraphs-item-pb-our-license{
				.slick{
					padding: 0px;
					.slick-slide{
						img{
							margin-left: auto;
							margin-right: auto;
						}
					} 
				}
			} 
		}
	}

	@include respond-to('small'){
		.node-m-our-values{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 27px 52px 28px 52px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			}
			.paragraphs-item-pb-slider-gallery{
				margin-bottom: 0px;
			}
			.paragraphs-item-pb-our-license{
				.slick{
					padding: 0px;
					.slick-slide{
						img{
							margin-left: auto;
							margin-right: auto;
						}
					} 
				}
			}
			.paragraphs-item-pb-circle-text{
				.field-name-field-description{
					p{
						br{
							display: none;
						}
					}
				}
			} 
		}
	}

}

.cb__mission{
	margin-bottom: 105px;
	.field-name-field-description{
		color: white;
		font-family: $Lora;
		font-size: 20px;
		font-style: italic;
		line-height: 1.5;
		text-align: center;
	}
	.title{
		color: #eba327;
		font-family: $MontserratAlter;
		font-size: 25px;
		font-weight: 700;
		line-height: 1.2;
		text-transform: uppercase;
		text-align: center;
		margin-top: 94px;
		margin-bottom: 20px;
		font-style: normal;

	}
}

.paragraphs-item-pb-our-license{
	margin-bottom: 120px;
	.field-name-field-description{
		margin-bottom: 43px;
	}
	.lic_title{
		font-family: $Lora;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		font-size: 30px;
		padding-right: 231px;
		line-height: 1;
	}
	.lic_subtitle{
		font-family: $Lora;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		font-size: 64px;
		line-height: 1;
		padding-left: 29px;
	}
	.slick{
		padding: 0 80px;
	}
}

@include respond-to('medium'){
}

@include respond-to('small'){
	.cb__mission{
		.field-name-field-image{
			.field-item{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.group-container{
			position: relative!important;
		}
		.field-name-field-description{
			font-size: 18px;
			margin-bottom: 60px;
			br{
				display: none;
			}
			.title{
				font-size: 22px;
				margin-top: 60px;
			}
		}
	}
	.paragraphs-item-pb-our-license{
		.lic_title{
			padding-right: 0px;
			font-size: 20px;
		}
		.lic_subtitle{
			font-size: 40px;
			padding-left: 0px;
		}
	} 
}