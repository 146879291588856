body.node-type-m-teacher{
	
	#main-wrapper{
		background: url(../images/nodes__bg.jpg) center top / cover no-repeat;
	}

	.node-m-teacher{
		padding: 151px 0 150px 0;
		> .node__container{

		}
		.two-col{
			display: flex;
			flex-direction:row;
			margin-bottom: 117px;
			align-items: flex-start;
			.tc{
				width: 50%;
			}
			.tc-1{
				position: relative;
			}
			.tc-2{
				padding: 10px 0 0 54px;
			}
		}
		.field-name-field-image{
			.field-item{
				background: none!important;
				img{
					opacity: 1;
					max-width: 100%;
					height: auto;
				}
			}
		}

		.title-and-position{
			background-color: rgba(235,163,39,.82);
			padding: 35px 20px 20px 20px;
			width: 503px;
			min-height: 160px;
			position: absolute;
			left: 0;
			bottom: 40px;
			text-align: center;
			.node-title{
				color: white;
				font-family: $Lora;
				font-size: 21px;
				font-weight: 400;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
			.field-name-field-position{
				color: white;
				font-size: 17px;
				font-weight: 300;
				line-height: 22px;
				p{
					margin: 0;
				}
			}
		}

		.field-name-body{
			font-size: 17px;
			font-weight: 300;
			line-height: 1.6;
			p{
				margin-bottom: 27px;
			}

		}
		
		.certificate-title{
			font-family: $Lora;
			font-size: 30px;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 37px;
		}

		.slick{
			padding: 0 80px;
		}

	}
	
	@include respond-to('medium'){
		.node-m-teacher{
			.two-col{
				flex-direction:column;
				align-items:center;
				justify-content:flex-start;
				.tc{
					width: initial;
				}
				.tc-2{
					padding: 60px 0 0 0;
					width: 100%;
				}
			}
		} 
		.slick{
			padding: 0!important;
			.slick-slide{
				img{
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	@include respond-to('small'){
		.node-m-teacher{
			padding: 60px 0 95px 0;
			> .node__container{
				padding-left: 0px;
				padding-right: 0px;
			}
			.two-col{
				flex-direction:column;
				align-items:center;
				justify-content:flex-start;
				margin-bottom: 76px;
				.tc{
					width: initial;
				}
				.tc-2{
					padding: 57px 0 0 0;
					width: 100%;
					ul{
						li{
							br{
								display: none;
							}
						}
					}
				}
			}
			.title-and-position{
				position: relative;
				bottom: 0px;
				width: 100%;
				padding: 42px 20px 40px 20px;
				.node-title{
					line-height: 1.2;
					margin-bottom: 13px;
				}
				.field-name-field-position{

				}
			}
			.field-name-body{
				padding-left: 10px;
				padding-right: 10px;
				line-height: 1.4;
			}
		} 
		.slick{
			padding: 0!important;
			.slick-slide{
				img{
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
			}
			.slick__arrow{
				.slick-prev{
					left: 10px;
				}
				.slick-next{
					right: 10px;
				}
			} 
		}
 
	}

}

.slick{
	position: relative;
	.slick__arrow{
		.slick-arrow{
			position: absolute;
			top: 50%;
			transform:translateY(-50%);
			background-color:rgba(56,67,89,.5);
			width: 40px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center;
			line-height: 1;
			font-size: 0;
			text-indent: -9999px;
			display: block;
			border: 0;
			padding: 0;
			margin: 0;
			&:hover{
				background-color:rgba(56,67,89,1);
			}
		}
		.slick-prev{
			background-image: url('../images/slidernav__left.png');
			left: 0px;
		}
		.slick-next{
			background-image: url('../images/slidernav__right.png');
			right: 0px;
		}
	}
}	

body.page-our-team{
	#main-wrapper{
		background: #ecf1f3;
	}
	
	.title-wrap{
		margin-top: 147px;
		padding: 0 0 22px 0;
		border-bottom: 2px solid #fdb52d;
		h1.page-title{
			margin: 0 0 0 0;
			line-height: 1;
		}
		.title{
			color: #384359;
			font-family: $Lora;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			font-size: 30px;
		}
		.subtitle{
			color: #384359;
			font-family: $Lora;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			font-size: 64px;
			padding-left: 25px;
		}
	}
}

@include respond-to('medium'){
	body.page-our-team{
		.title-wrap{
			margin-top: 110px;
		}
	} 
}

@include respond-to('small'){
	body.page-our-team{
		.title-wrap{
			margin-top: 90px;
			.title{
				text-align: center;
				font-size: 20px;
			}
			.subtitle{
				font-size: 40px;
				text-align: center;
				padding-left: 0px;
			}
		}
	} 
}

.view-v-our-team{
	.view-filters{
		margin-bottom: 132px;
		.form-control.jq-selectbox{
			margin-left: auto;
		}
	}
	.view-content{
		.views-row{
			width: 256px;
			margin-bottom: 109px;
			&:hover{
				.node-m-teacher{
					.node-detail{
						display: block;
					}
					.field-name-field-image{
						a{
							&:before{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(253, 181, 45, 0.9);
							}
						}
					}
					.node-title{
						a{
							color: #fdb52d;
						}
					}
				}	
			}
			.node-m-teacher{
				position: relative;
				.node-detail{
					display: none;
					font-size: 0;
					a{
						z-index: 5;
						position: absolute;
						text-align: center;
						top: 132px;
						left: 50%;
						transform:translateX(-50%);
						margin: 0 0 0 0;
						padding: 17px 26px 17px 26px;
						border: 1px solid white;
						border-radius: 25px;
						display: inline-block;
						color: white;
						font-family: $MontserratAlter;
						font-size: 14px;
						font-weight: 700;
						line-height: 1;
						text-transform: uppercase;
					}
				}
				.field-name-field-image{
					border-bottom: 7px solid #fdb52d;
					margin-bottom: 25px;
					.field-item{
						background: none!important;
						a{
							display: block;
							position: relative;
						}
						img{
							opacity: 1;
						}
					}
				}
				.node-title{
					text-align: center;
					margin-bottom: 19px;
					a{
						color: #384359;
						font-family: $MontserratAlter;
						font-size: 18px;
						font-weight: 700;
						line-height: 1.3;
					}
				}
				.field-name-field-position{
					font-size: 17px;
					font-weight: 300;
					line-height: 1.3;
					text-align: center;
				}
			}
		}
		
			.node-m-teacher{
				width: 256px;
				position: relative;
				margin-bottom: 109px;
				&:hover{
					.node-detail{
						display: block;
					}
					.field-name-field-image{
						a{
							&:before{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(253, 181, 45, 0.9);
							}
						}
					}
					.node-title{
						a{
							color: #fdb52d;
						}
					}
				}
				.node-detail{
					display: none;
					font-size: 0;
					a{
						z-index: 5;
						position: absolute;
						text-align: center;
						top: 132px;
						left: 50%;
						transform:translateX(-50%);
						margin: 0 0 0 0;
						padding: 17px 26px 17px 26px;
						border: 1px solid white;
						border-radius: 25px;
						display: inline-block;
						color: white;
						font-family: $MontserratAlter;
						font-size: 14px;
						font-weight: 700;
						line-height: 1;
						text-transform: uppercase;
					}
				}
				.field-name-field-image{
					border-bottom: 7px solid #fdb52d;
					margin-bottom: 25px;
					.field-item{
						background: none!important;
						a{
							display: block;
							position: relative;
						}
						img{
							opacity: 1;
						}
					}
				}
				.node-title{
					text-align: center;
					margin-bottom: 19px;
					a{
						color: #384359;
						font-family: $MontserratAlter;
						font-size: 18px;
						font-weight: 700;
						line-height: 1.3;
					}
				}
				.field-name-field-position{
					font-size: 17px;
					font-weight: 300;
					line-height: 1.3;
					text-align: center;
				}
			}
		
	}
	.item-list{
		ul.pager{
			margin: 0px 0 60px 0;
		}
	} 
}

.team-inner{
	padding: 0 0px 60px 0px;
	margin-top: -82px;
	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		padding: 0 112px 0px 112px;
	}
}

.paragraphs-item-pb-ins-team{
	.team-title{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 30px;
		text-align: center;
		padding-right: 307px;
	}
	.team-subtitle{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 64px;
		text-align: center;
		padding-left: 67px;
		margin-top: 1px;
	}
	.field-name-field-description{
		margin-bottom: 41px;
	}
	.team-insert{
		.slick{
			padding: 0 112px;
			.slick__arrow{
				.slick-arrow{
					top: 23%;
					transform:none;
				}
			} 
		} 
		.single-row {
			.node-m-teacher {
				margin-left: auto;
    			margin-right: auto;
			}
		}
	}
}


@include respond-to('medium'){

	.paragraphs-item-pb-ins-team{
		.team-insert{
			.slick{
				padding: 0 60px;
			}
			.view-content{
				.slick-slide{
					.node-m-teacher{
						margin:0 auto 60px auto;
					}
				} 
			} 
		} 
	} 
	
	.team-inner{
		.view-content{
			padding: 0 60px 0px 60px;
		}
	} 

}

@include respond-to('small'){

	.paragraphs-item-pb-ins-team{
		.team-title{
			font-size: 20px;
			padding-right: 0px;
		}
		.team-subtitle{
			font-size: 40px;
			padding-left: 0px;
			margin-top: 10px;
		}
		.field-name-field-description{
			margin-bottom: 36px;
		}
		.team-insert{
			.slick{
				padding: 0px;
				.slick__arrow{
					.slick-arrow{
						top: 25%;
					}
				} 
			}
			.view-content{
				.slick-slide{
					.node-m-teacher{
						margin:0 auto 57px auto;
					}
				} 
			} 
		} 
	} 

	.view-v-our-team{
		.view-filters{
			margin-bottom: 85px;
			.form-control.jq-selectbox{
				margin-right: auto;
			}
		}
	}

	.team-inner{
		padding-left: 0px!important;
		padding-right: 0px!important;
		margin-top: 45px;
		.view-content{
			padding: 0;
			justify-content:center;
		}
	}

}