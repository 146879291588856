// Page Layout
// -----------------------------------------------------------------------------
#page-wrapper,
#all-wrapper{
	width: 100%;
	position: relative;
	overflow: hidden;
}

a{
	text-decoration: none;
	outline: none!important;
	&:hover,
	&:focus{
		text-decoration: none;
		outline: none!important;
	}
}

*:focus{
	outline: 0;
}

.field-type-image{
	.field-item{
		background-repeat: no-repeat;
		> img{
			opacity: 0;
		}
	}
}

.owl-carousel .owl-item img{
	width: initial;
}

.ajax-progress-throbber { display: none}

.page-user{
	#main #content{
		@include make-container();
	}
}

.container{
	@include respond-to('small'){
		padding-left: 10px;
		padding-right: 10px;
	}
}

#main-wrapper{
	position: relative;
}

.views-exposed-widgets{
	margin-bottom: 0px;
}

.views-exposed-form{
	.views-exposed-widget{
		float: none;
		padding: 0;
	}
} 

#gmap-canvas{
	height: 500px;
}

.map-wrapper{
	position: relative;
	/*&:before{
		content: '';
		position: absolute;
		top: 40px;
		right: calc((100% - #{$container-lg}) / 2);
		margin-right: 40px;
		width: 78px;
		height: 104px;
		background: url('../images/placeholder_Kyiv.png') no-repeat;
		z-index: 10;
	}*/
	#block-block-3{
		position: absolute;
		top: 90px;
		left: calc((100% - #{$container-lg}) / 2);
		margin-left: -50px;
		z-index: 10;
	}
	.map-addr-wrap{
		width: 606px;
		height: 226px;
		background-color: rgba(255,255,255,.9);
		padding: 20px 47px 20px 52px;
		display: flex;
		flex-direction:row;
		align-items:center;
		.maw__title{
			font-family: $Lora;
			font-size: 30px;
			font-weight: 400;
			text-transform: uppercase;
			margin-right: 30px;
		    position: relative;
		    padding: 27px 19px 27px 0px;
		    line-height: 1.2;
		    border-right: 5px solid #fdb52d;
		    text-align: right;
		    &:before{
		    	content: '';
	    	    position: absolute;
	    	    top: 0;
	    	    right: 0;
	    	    width: 85px;
	    	    height: 14px;
	    	    border-top: 5px solid #fdb52d;
		    	border-left: 5px solid #fdb52d;
		    }
		    &:after{
		    	content: '';
		    	position: absolute;
		    	bottom: 0;
		    	right: 0;
		    	width: 85px;
		    	height: 14px;
		    	border-bottom: 5px solid #fdb52d;
		    	border-left: 5px solid #fdb52d;
		    }
		}
		.maw__body{
			font-size: 17px;
			line-height: 1.42;
			font-weight: 300;
			b{
				font-weight: 700;
			}
		}
	}
}


.big-image{
	position: relative;
	.bi__container{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 5;
	}
}

#content{
	.field{
		ul{
			padding: 0;
			margin: 0 0 63px 0;
			li{
				font-family: $Lora;
				font-size: 18px;
				font-weight: 400;
				line-height: 1.12;
				padding-left: 36px;
				padding-top: 3px;
				position: relative;
				list-style: none;
				&:not(:last-child){
					margin-bottom: 17px;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 25px;
					height: 25px;
					border-radius: 100%;
					border:5px solid #eba327;
				}
			}
		}
		ol{
			padding: 0;
			margin: 0 0 63px 0;
			counter-reset: item;
			li{
				font-family: $Lora;
				font-size: 18px;
				font-weight: 700;
				line-height: 1.12;
				padding-left: 27px;
				padding-top: 3px;
				position: relative;
				list-style: none;
				&:not(:last-child){
					margin-bottom: 17px;
				}
				&:before{
					content: counter(item)' |  ';
					counter-increment: item;
					position: absolute;
					top: 3px;
					left: 0;
					color: #eba327;
					font-family: $Lora;
					font-size: 18px;
					font-weight: 700;
				}
			}
		}	
	}
}

@include respond-to('medium'){
	.map-wrapper{
		#block-block-3{
			margin-left: 0px;
			top:0px;
			left: 0px;
			width: 100%;
			.map-addr-wrap{
				width: 100%;
				height: initial;
				padding: 30px 47px 30px 71px;
				.maw__title{
					margin-right: 29px;
				}
			}
		}
	}
}
@include respond-to('small'){
	.map-wrapper{
		#block-block-3{
			margin-left: 0px;
			top:0px;
			left: 0px;
			width: 100%;
			position: static;
			.map-addr-wrap{
				width: 100%;
				height: initial;
				padding: 30px 45px 37px 45px;
				flex-direction:column;
				.maw__title{
					margin-right: 0px;
					margin-bottom: 31px;
				}
				.maw__body{
					text-align: center;
				}
			}
		}
	}
	#gmap-canvas{
		height: 400px;
	}

	#content{
		.field{
			ul{
				margin: 0 0 42px 0;
				li{
					line-height: 1.27;
					padding-left: 35px;
					padding-top: 4px;
					&:before{
						top: 1px;
					}
					&:not(:last-child){
						margin-bottom: 17px;
					}
				}
			}

		} 
	}

}

#main #page-header {
    .tabs {
    	padding-top: 50px;
    }
}

br{
	&.only-mobile{
		display: none;
		@include respond-to('small'){
			display: block;
		}
	}
}