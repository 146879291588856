body.node-type-m-intro-garden{

	.node-m-intro-garden{
		
		.big-image{
			margin-bottom: 81px;
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 90px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 90px;
				}
			}
		}

	}
	
	.cr__text-1{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 0px 0px 81px 0px;
		.cr__container{
			padding-right: 60px;
			padding-left: 60px;
		}
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 27px;
			}
		}
	}

	.cr__text-2{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 27px 0 82px 0;
		.cr__container{
			padding-right: 60px;
			padding-left: 60px;
		}
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
		img{
			max-width: 100%;
			height: auto;
		}
		img[align="left"]{
			margin: 22px 50px 22px 0;
		}
	}
	
	@include respond-to('medium'){
		.node-m-intro-garden{
			.big-image{
				margin-bottom: 60px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
			.cr__text-1{
				.cr__container{
					padding-right: 0px;
					padding-left: 0px;
				}
				p{
					br{
						display: none;
					}
				}
			} 

			.cr__text-2{
				.cr__container{
					padding-right: 0px;
					padding-left: 0px;
				}
			} 
		} 
	}

	@include respond-to('small'){
		.node-m-intro-garden{
			.big-image{
				margin-bottom: 50px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 27px 52px 28px 52px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			} 
			.cr__text-1{
				.cr__container{
					padding-right: 10px;
					padding-left: 10px;
				}
				p{
					br{
						display: none;
					}
				}
			} 

			.cr__text-2{
				.cr__container{
					padding-right: 10px;
					padding-left: 10px;
				}
				img[align="left"]{
					margin: 22px 0px 22px 0;
				}
			} 

			.paragraphs-item-pb-slider-gallery + .how-introduce{
				margin-top: 60px;
			}

		}
	}

	&.page-node-167,
	&.page-node-379,
	&.page-node-380,
	&.page-node-196 {
		
		.node-m-intro-garden .big-image {
			margin-bottom: 0px;
		}
	}
	&.page-node-167,
	&.page-node-379 {
		.paragraphs-item-pb-content-background {
			margin-bottom: 0px;
		}
	}

}