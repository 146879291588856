body.node-type-m-edu-approach{

	.node-m-edu-approach{
		
		.big-image{
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 90px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 90px;
				}
			}
		}

	}
	
	.np-word-1{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 81px 0 83px 0;
		text-align: center;
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}

	.np-word-2{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 81px 0 108px 0;
		text-align: center;
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
	
	.paragraphs-item-pb-ins-team{
		.team-title{
			padding-right: 262px;
		}
		.team-subtitle{
			padding-left: 24px;
		}
	} 

	@include respond-to('medium'){
		.node-m-edu-approach{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
			.np-word-1,
			.np-word-2{
				br{
					display: none;
				}
			}
		} 
	}

	@include respond-to('small'){
		.node-m-edu-approach{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 27px 52px 28px 52px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			} 

			.np-word-1,
			.np-word-2{
				br{
					display: none;
				}
			}

			.np-word-1{
				line-height: 1.4;
				padding: 61px 0 85px 0;
			}

			.np-word-2{
				line-height: 1.4;
				padding: 85px 0 85px 0;
			}
			
			.paragraphs-item-pb-ins-team{
				.team-title{
					padding-right: 0px;
				}
				.team-subtitle{
					padding-left: 0px;
				}

				.team-insert{
					.view-content{
						.slick-slide{
							.node-m-teacher{
								margin: 0 auto 27px auto;
							}
						} 
					} 
				} 

			} 

			.paragraphs-item-pb-slider-gallery{
				.slider-for{
					margin-bottom: 10px;
				}
			} 

		}
	}

}

.cb__edu-way{
	
	.field-name-field-description{
		padding-top: 115px;
	}

	.title{
		color: #eba327;
		font-family: $Lora;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.17;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 44px;
	}

	.directions{
		display: flex;
		flex-direction:row;
		justify-content:center;
		.dir{
			&:not(:last-child){
				margin-right: 132px;
			}
			ul{
				li{
					font-weight: 700!important;
					&:hover{
						color: #efb550;
						text-decoration: underline;
						&:before{
							background-color: #eba327;
						}
					}
					a{
						color: #384359;
						text-decoration: none;
						font-weight: 700!important;
						&:hover{
							color: #efb550;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

}

@include respond-to('medium'){
	.cb__edu-way{
		.directions{
			.dir:not(:last-child){
				margin-right: 50px;
			}
		} 
	} 
}

@include respond-to('small'){
	.cb__edu-way{
		padding-bottom: 12px;
		.title{
			font-size: 20px;
			margin-bottom: 34px;
		}
		.directions{
			flex-direction:column;
			align-items:flex-start;
			justify-content:flex-start;
			padding: 0 23px 0 29px;
			.dir{
				&:not(:last-child){
					margin-right: 0px;
				}
			}
		}
		.field-name-field-image{
			.field-item{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-size: cover;
			}
		}

		.group-container{
			position: relative!important;
		}

		.field-name-field-description{
			padding-top: 87px;
		}
	}
}