.item-list{
	ul.pager{
		font-size: 0;
		margin: 60px 0 60px 0;
		padding: 0;
		li{
			display: inline-flex;
			margin: 0 8px;
			vertical-align: top;
			a{
				display: block;
			}
		}

		.pager-item,
		.pager-current{
			height: 40px;
			align-items:center;
			*{
				padding: 0;
				border-radius: 0;
				border:0;
				background-color: transparent;
				float: none;
				margin: 0;
				font-family: $Lora;
				font-size: 20px;
				font-weight: 400;
				line-height: 1;
				text-transform: uppercase;
				color: #384359;
				&:hover{
					color: #fdb52d;
				}
			}
		}

		.pager-ellipsis{
			height: 40px;
			align-items:center;
			font-family: $Lora;
			font-size: 20px;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			color: #384359;
		}
		
		.pager-current{
			*{
				color: #fdb52d;
			}
		}

		.pager-first,
		.pager-last{
			display: none;
		}
		.pager-previous{
			margin: 0 32px 0 0;
			a{
				background-image: url('../images/slidernav__left.png');
			}
		}
		.pager-next{
			margin: 0 0px 0 32px;
			a{
				background-image: url('../images/slidernav__right.png');
			}
		}
		.pager-previous,
		.pager-next{
			a{
				float: none;
				padding: 0;
				margin: 0;
				border:0;
				border-radius: 0;
				background-color:rgba(56,67,89,.5);
				width: 40px;
				height: 40px;
				background-repeat: no-repeat;
				background-position: center;
				line-height: 1;
				&:hover{
					background-color:rgba(56,67,89,1);
				} 
			}
		}
	}
}

@include respond-to('medium'){
	.item-list{
		ul.pager{
			margin: 40px 0 40px 0;
		}
	} 
}

@include respond-to('small'){
	.item-list{
		ul.pager{
			margin: 30px 0 30px 0;
		}
	}
}