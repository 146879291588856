body.node-type-m-school{

	.node-m-school{
		
		.big-image{
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 240px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 240px;
				}
			}
		}

	}
	
	.paragraphs-item-pb-set-properties{
		margin-bottom: 116px;
		> .content{
			> .field-name-field-title{
				font-size: 30px;
				padding-right: 301px;
				padding-left: 0px;
				text-align: center;
			}
			> .field-name-field-subtitle{
				padding-left: 236px;
				font-size: 64px;
			}
			
		}
	} 
	
	.front-grey-group{
		margin-bottom: 109px;
	}

	@include respond-to('medium'){
		.node-m-school{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
			.paragraphs-item-pb-set-properties{
				>.content{
					>.field-name-field-title{
						padding-right: 0px;
					}
					> .field-name-field-subtitle{
						padding-left: 0px;
					}
				}
			}
		} 
	}

	@include respond-to('small'){
		.node-m-school{
			.big-image{
				margin-bottom: 0px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 27px 35px 28px 35px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			}
			.paragraphs-item-pb-set-properties{
				>.content{
					>.field-name-field-title{
						padding-right: 0px;
						font-size:40px
					}
					> .field-name-field-subtitle{
						padding: 0px 20px;
						font-size: 20px;
					}
				}
			}
		}
	}

}

.paragraphs-item-pb-big-circle-text-set{
	margin-bottom: 106px;
	> .content{
		> .field-name-field-title{
			font-family: $Lora;
			font-size: 30px;
			font-weight: 400;
			line-height: 1.17;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 34px;
			p{
				margin: 0;
			}
		}
	}
	
	.paragraphs-items-field-pbbcts-paragraphs{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
	}
}

.paragraphs-item-pb-big-circle-text{
	width: calc(100% / 3);
	> .content{

	}
	.group-circle{
		width: 230px;
		height: 230px;
		border-radius: 100%;
		display: flex;
		flex-direction:column;
		align-items:center;
		text-align: center;
		margin: 0 auto 31px auto;
		position: relative;
		.field-name-field-pbbct-circle{
			margin-top: 55px;
			.field-item{
				background: none!important;
				img{
					opacity: 1;
				}
			}
		}
		.field-name-field-title{
			color: white;
			font-family: $Lora;
			font-weight: 400;
			text-transform: uppercase;
			font-size: 17px;
			margin-top: 14px;
			line-height: 1;
			p{
				margin: 0;
			}
		}
		.field-name-field-title-2{
			color: white;
			font-family: $Lora;
			font-weight: 400;
			line-height: 30px;
			text-transform: uppercase;
			font-size: 30px;
			margin-top: 1px;
		}
		.field-name-field-link{
			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				z-index: 10;
				font-size: 0;
				text-indent: -9999px;
			}
		}
	}
	.field-name-field-description{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		text-align: center;
	}
	&:nth-child(3n+1){
		.group-circle{
			background-color: #ef4665;
		}
	}
	&:nth-child(3n+2){
		.group-circle{
			background-color: #eba327;
		}
	}
	&:nth-child(3n+3){
		.group-circle{
			background-color: #4f5e7d;
		}
	}
}

@include respond-to('medium'){
	.paragraphs-item-pb-big-circle-text-set{
		margin-bottom: 40px;
		>.content{
			>.field-name-field-title{
				font-size: 26px;
				color: #384359;
			}
		}
		.paragraphs-items-field-pbbcts-paragraphs{
			justify-content:center;
		}
	}

	.paragraphs-item-pb-big-circle-text{
		width: 50%;
		margin-bottom: 35px;
	}
}
@include respond-to('small'){
	.paragraphs-item-pb-big-circle-text-set{
		margin-bottom: 48px;
		>.content{
			>.field-name-field-title{
				font-size: 20px;
				color: #384359;
				line-height: 1.2;
				padding: 0 1px;
				margin-bottom: 35px;
				br{
					display: none;
				}
				span{
					text-transform: uppercase;
				}
			}
		}
		.paragraphs-items-field-pbbcts-paragraphs{
			justify-content:center;
		}
	}

	.paragraphs-item-pb-big-circle-text{
		width: 100%;
		margin-bottom: 33px;
		.group-circle{
			margin: 0 auto 33px auto;
			.field-name-field-title-2{
				line-height: 1.3;
				margin-top: 6px;
			}
		} 
		.field-name-field-description{
			line-height: 1.36;
			br{
				display: none;
			}
		}
	}
}

.paragraphs-item-pb-teacher-quote{
	margin-bottom: 196px;
	> .content{
		position: relative;
	}
	.group-inner{
		position: absolute;
		top: 80px;
		left: 0;
		right: 0;
		z-index: 5;
		display: flex;
		flex-direction:row;
		.field-name-field-pbtq-teacher{
			margin-right: 114px;
			.node-m-teacher{
				.teacher{
					position: relative;
					.field-name-field-image{
						.field-item{
							background: none !important;
							img{
								opacity: 1;
							}
						}
					}
					.title-and-position{
						background-color: rgba(235,163,39,0.82);
						padding: 35px 20px 20px 20px;
						width: 503px;
						min-height: 160px;
						position: absolute;
						left: 0;
						bottom: 40px;
						text-align: center;
						.node-title{
							color: white;
						    font-family: $Lora;
						    font-size: 21px;
						    font-weight: 400;
						    text-transform: uppercase;
						    margin-bottom: 15px;
						}
						.field-name-field-position{
							color: white;
							font-size: 17px;
							font-weight: 300;
							line-height: 22px;
						}
					}
				}
			}
		}
		.group-title-desc{
			width: calc(100% - 667px);
			margin-top: 78px;
			.field-name-field-title{
				color: white;
				font-family: $Lora;
				font-size: 30px;
				font-weight: 400;
				text-transform: uppercase;
				line-height: 1.2;
				margin-bottom: 39px;
				p{
					margin: 0;
				}
			}
			.field-name-field-description{
				color: white;
				font-family: $Lora;
				font-size: 18px;
				font-style: italic;
				line-height: 1.5;
			}
		}
	}
}

@include respond-to('medium'){
	.paragraphs-item-pb-teacher-quote{
		margin-bottom: 110px;
		> .content{
			> .field-name-field-image{
				.field-item{
					background-size: cover;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		
		.group-inner{
			position: relative;
			flex-direction: column;
			align-items: center;
			bottom: 0;
			top: 0;
			padding: 50px 0;
			.field-name-field-pbtq-teacher{
				margin-right: 0;
				margin-bottom: 20px;
			}
			.group-title-desc{
				width: 100%;
				margin-top: 40px;
				.field-name-field-description{
					br{
						display: none;
					}
				}
			}
		}
	} 
}

@include respond-to('small'){
	.paragraphs-item-pb-teacher-quote{
		margin-bottom: 90px;
		> .content{
			> .field-name-field-image{
				display: none;
				.field-item{
				}
			}
		}
		
		.group-inner{
			position: relative;
			flex-direction: column;
			align-items: center;
			bottom: 0;
			top: 0;
			padding-left: 0px;
			padding-right: 0px;
			.field-name-field-pbtq-teacher{
				margin-right: 0;
				.node-m-teacher{
					.teacher{
						.field-name-field-image{
							img{
								max-width: 100%;
								height: auto;
							}
						}
						.title-and-position{
							width: 100%;
							position: relative;
							bottom: 0;
							padding: 42px 20px 30px 20px;
							.node-title{
								line-height: 1.2;
								margin-bottom: 13px;
							}
							.field-name-field-position{
								
							}
						}
					} 
				} 
			}
			.group-title-desc{
				width: 100%;
				margin-top: 43px;
				padding-left: 10px;
				padding-right: 10px;
				.field-name-field-title{
					font-size: 25px;
					margin-bottom: 18px;
					color: #ef4665;
				}
				.field-name-field-description{
					font-size: 17px;
					line-height: 1.48;
					padding: 0 6px;
					color: #384359;
					br{
						display: none;
					}
				}
			}
		}
	} 
}