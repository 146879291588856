body.node-type-m-preschool{

	.node-m-preschool{
		background: url("../images/preschool_podkladka.png") center top no-repeat;
		.big-image{
			.field-name-field-image{
				.field-item{
					background-size: cover;
					background-position: top center!important;
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-start;
				height: 100%;
				.field-name-field-fortmatted-title{
					text-align: center;
					line-height: 1;
					font-family: $Neucha;
					font-weight: 400;
					padding-top: 151px;
					margin-bottom: 35px;
					.title-1{
						color: #eba327;
						text-transform: uppercase;
						font-size: 40px;
						margin-bottom: 5px;
					}
					.title-2{
						color: #eba327;
						text-transform: uppercase;
						font-size: 70px;
						margin-bottom: 2px;
					}
					.title-3{
						font-size: 30px;
						margin-bottom: 30px;
					}
					.title-4{
						color: #ef4665;
						font-size: 40px;
					}
				}
				.field-name-body{
					color: white;
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(235,163,39,0.9);
					padding: 28px 59px 19px 61px;
					line-height: 1.24;
					margin-left: 0px;
					margin-top: 0px;
					border-radius: 62px;
				}
			}
		}

	}
	
	.front-grey-group{
		margin-top: -203px;
		margin-bottom: 80px;
	}
	
	.big-teach-union{
		.paragraphs-item-pb-big-circle-text-set{
			position: relative;
			z-index: 5;
		}
		.paragraphs-item-pb-teacher-quote{
			margin-top: -350px;
			margin-bottom: 88px;
		}
	}

	.paragraphs-item-pb-big-circle-text-set{
		> .content{
			> .field-name-field-title{
				color: white;
				margin-bottom: 28px;
				span{
					text-transform: none;
				}
			}
		}
	}

	.paragraphs-item-pb-big-circle-text{
		.group-circle{
			background-color: white!important;
			box-shadow: 0 12px 12px rgba(0, 0, 0, 0.12);
			.field-name-field-title{
				font-size: 20px;
				color: #4f5e7d;
				margin-top: 7px;
				line-height: 1.2;
			}
			.field-name-field-title-2{
				font-size: 14px;
				color: #4f5e7d;
			}
		} 
	} 
	
	.paragraphs-item-pb-teacher-quote{
		.group-inner{
			top: auto;
			bottom: -58px;
			.field-name-field-pbtq-teacher{
				.node-m-teacher{
					.teacher{
						.title-and-position{
							background-color: rgba(239,70,101,.82);
						}
					} 
				} 
			} 
			.group-title-desc{
				text-align: center;
				margin-top: 88px;
				.field-name-field-title{
					color: #ef4665;
					font-family: $Neucha;
					font-size: 33px;
					margin-bottom: 31px;
				}
				.field-name-field-description{
					color: #384359;
				}
			}
		} 
	} 

	.ps__text-1{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 79px 0 105px 0;
		text-align: center;
		.ta__container{
			padding-left: 65px;
			padding-right: 65px;
		}
		.title{
			color: #eba327;
			font-family: $Lora;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 29px;
			line-height: 1.38;
		}
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 27px;
			}
		}
	}

	.how-introduce{
		color: #384359;
		.title{
			font-family: $Neucha;
			color: #ef4665;
		}
		span{
			color: #ef4665;
		}
		a.ctools-use-modal{
			margin-top: 24px;
			background-color: #ef4665;
			padding: 18px 23px 18px 23px;
		}
	}
	
	@include respond-to('medium'){
		
		.node-m-preschool{
			.big-image{
				.bi__container{
					align-items:center;
				}
			} 
		} 

		.front-grey-group{
			margin-top: -103px;
			margin-bottom: 60px;
		}
		
		.big-teach-union{
			.paragraphs-item-pb-teacher-quote{
				margin-top: 0px;
				margin-bottom: 50px;
			}
		} 
		
		.paragraphs-item-pb-teacher-quote{
			> .content{
				> .field-name-field-image{
					.field-item{
						background-size: cover;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
			
			.group-inner{
				position: relative;
				flex-direction: column;
				align-items: center;
				bottom: 0;
				top: 0;
				padding: 50px 0;
				.field-name-field-pbtq-teacher{
					margin-right: 0;
				}
				.group-title-desc{
					width: 100%;
					margin-top: 40px;
				}
			}
		} 

		.paragraphs-item-pb-big-circle-text-set{
			margin-bottom: 40px;
			>.content{
				>.field-name-field-title{
					font-size: 26px;
					color: #384359;
				}
			}
			.paragraphs-items-field-pbbcts-paragraphs{
				justify-content:center;
			}
		}

		.paragraphs-item-pb-big-circle-text{
			width: 50%;
			margin-bottom: 35px;
		}

		.ps__text-1{
			padding: 40px 0 80px 0;
			.ta__container{
				padding-left: 20px;
				padding-right: 20px;
			}
			br{
				display: none;
			}
		}

		.how-introduce{
			br{
				display: none;
			}
			strong{
				padding-left: 5px;
			}
		}

	}

	@include respond-to('small'){
		.node-m-preschool{
			background: none;
			.big-image{
				margin-bottom: 37px;
				.field-name-field-image{
					.field-item{
						background: none!important;
						img{
							opacity: 1;
							max-width: 100%;
							height: auto;
						}
					}
				}
				.bi__container{
					position: relative;
					align-items:center;
					.field-name-field-fortmatted-title{
						padding-top: 36px;
						margin-bottom: 28px;
						.title-1{
							font-size: 25px;
							margin-bottom: 11px;
						}
						.title-2{
							font-size: 38px;
							margin-bottom: 12px;
						}
						.title-3{
							font-size: 25px;
							margin-bottom: 14px;
						}
						.title-4{
							font-size: 25px;
						}
					}
					.field-name-body{
						font-size: 15px;
						padding: 37px 30px 25px 30px;
						text-align: center;
					}
				}
			} 
		} 

		.front-grey-group{
			margin-top: 0px;
			margin-bottom: 86px;
		}
		
		.big-teach-union{
			.paragraphs-item-pb-teacher-quote{
				margin-top: 0px;
				margin-bottom: 50px;
			}
		} 
		
		.paragraphs-item-pb-teacher-quote{
			> .content{
				> .field-name-field-image{
					display: none;
					.field-item{
					}
				}
			}
			
			.group-inner{
				position: relative;
				flex-direction: column;
				align-items: center;
				bottom: 0;
				top: 0;
				padding-left: 0px;
				padding-right: 0px;
				.field-name-field-pbtq-teacher{
					margin-right: 0;
					.node-m-teacher{
						.teacher{
							.field-name-field-image{
								img{
									max-width: 100%;
									height: auto;
								}
							}
							.title-and-position{
								width: 100%;
								position: relative;
								bottom: 0;
								padding: 42px 20px 30px 20px;
								.node-title{
									line-height: 1.2;
									margin-bottom: 13px;
								}
								.field-name-field-position{
									
								}
							}
						} 
					} 
				}
				.group-title-desc{
					width: 100%;
					margin-top: 43px;
					padding-left: 10px;
					padding-right: 10px;
					.field-name-field-title{
						font-size: 25px;
						margin-bottom: 18px;
					}
					.field-name-field-description{
						font-size: 17px;
						line-height: 1.48;
						padding: 0 6px;
						br{
							display: none;
						}
					}
				}
			}
		} 
		
		.paragraphs-item-pb-big-circle-text-set{
			margin-bottom: 48px;
			>.content{
				>.field-name-field-title{
					font-size: 20px;
					color: #384359;
					line-height: 1.2;
					padding: 0 1px;
					margin-bottom: 35px;
					br{
						display: none;
					}
					span{
						text-transform: uppercase;
					}
				}
			}
			.paragraphs-items-field-pbbcts-paragraphs{
				justify-content:center;
			}
		}

		.paragraphs-item-pb-big-circle-text{
			width: 100%;
			margin-bottom: 33px;
			.group-circle{
				margin: 0 auto 33px auto;
				.field-name-field-title-2{
					line-height: 1.3;
					margin-top: 6px;
				}
			} 
			.field-name-field-description{
				line-height: 1.36;
				br{
					display: none;
				}
			}
		}
		
		.ps__text-1{
			padding: 53px 0 110px 0;
			line-height: 1.5;
			.ta__container{
				padding-left: 10px;
				padding-right: 10px;
			}
			.title{
				font-size: 18px;
				line-height: 1.4;
				margin-bottom: 26px;
			}
			br{
				display: none;
			}
			p{
				&:not(:last-child){
					margin-bottom: 25px;
				}
			}
		}

	}

}

@media screen and (min-width: 768px) and (max-width: 1199px) {
	body.node-type-m-preschool.page-node-168 .node-m-preschool .big-image .field-name-field-image .field-item,
	body.node-type-m-preschool.page-node-382 .node-m-preschool .big-image .field-name-field-image .field-item {
		background-position: top left !important;
	}
} 
 