body.node-type-m-yong-school{

	.node-m-yong-school{
		
		.big-image{
			margin-bottom: 120px;
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 90px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 90px;
				}
			}
		}

	}
	
	.how-conduct{
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		padding: 27px 0 114px 0;
		text-align: center;
		.title{
			color: #eba327;
			font-family: $Lora;
			font-size: 30px;
			font-weight: 400;
			line-height: 1.17;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 27px;
		}
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}

	.paragraphs-item-pb-set-properties{
		margin-bottom: 115px;
		> .content{
			> .field-name-field-title{
				font-size: 30px;
				padding-right: 209px;
				padding-left: 0px;
				text-align: center;
			}
			> .field-name-field-subtitle{
				padding-left: 163px;
				font-size: 64px;
			}
			
		}
	} 

	@include respond-to('medium'){
		.node-m-yong-school{
			.big-image{
				margin-bottom: 60px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
			.how-conduct{
				br{
					display: none;
				}
			}
		} 
	}

	@include respond-to('small'){
		.node-m-yong-school{
			.big-image{
				margin-bottom: 62px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 17px 52px 14px 52px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			} 
			.paragraphs-item-pb-circle-text-set{
				padding-bottom: 30px;
				margin-bottom: 59px;
				.field-name-field-title{
					font-size: 20px;
					margin-bottom: 37px;
				}
			} 
			
			.how-conduct{
				line-height: 1.4;
				padding: 27px 0 108px 0;
				.title{
					font-size: 20px;
					margin-bottom: 30px;
				}
				.field-name-field-text-area{
					p{
						br{
							display: none;
						}
					}
				}
			}

			.paragraphs-item-pb-set-properties{
				>.content{
					>.field-name-field-title{
						font-size: 40px;
						padding-right: 0px;
					}
					>.field-name-field-subtitle{
						font-size: 20px;
						padding: 0 20px;
					}
				}
			}

		}
	}

}

.paragraphs-item-pb-form-edu-set{
	margin-bottom: 111px;
	> .content{
		> .field-name-field-title{
			color: #eba327;
			font-family: $Lora;
			font-size: 30px;
			font-weight: 400;
			line-height: 1.17;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 23px;
		}
	}
	.paragraphs-items-field-pbfes-paragraphs{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
	}
}

.paragraphs-item-pb-form-edu{
	position: relative;
	width: 584px;
	margin-bottom: 6px;
	> .content{

	}
	.field-name-field-image{
		.field-item{
			background: none!important;
			img{
				opacity: 1;
			}
		}
	}
	.field-name-field-image-2{
		display: none;
		box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
		.field-item{
			background: none!important;
			img{
				opacity: 1;
			}
		}
	}
	.group-wrap{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 9px;
		.field-name-field-title{
			color: white;
			font-family: $MontserratAlter;
			font-size: 25px;
			font-weight: 700;
			line-height: 1.2;
			text-transform: uppercase;
			margin-bottom: 20px;
			a{
				color: white;
			}
			.field-item{
				> *{
					display: inline-block;
				}
			}
		}
		.field-name-field-description{
			font-size: 17px;
			font-weight: 300;
			color: white;
			line-height: 1.2;
			a{
				color: #fdb52d;
				text-decoration: underline;
			}
		}
	}
	&:hover{
		.field-name-field-image{
			display: none;
		}
		.field-name-field-image-2{
			display: block;
		}
		.group-wrap{
			padding-top: 20px;
			.field-name-field-title{
				.field-item{
					> *{
						line-height: 1;
						padding: 17px 40px 17px 40px;
						background-color: #fdb52d;
						border-radius: 29px;
					}
				}
			}
			.field-name-field-description{
				display: none;
			}
		}
	}
}

@include respond-to('medium'){
	.paragraphs-item-pb-form-edu-set{
		.paragraphs-items-field-pbfes-paragraphs{
			justify-content:center;
		}
	} 
}

@include respond-to('small'){
	.paragraphs-item-pb-form-edu-set{
		.paragraphs-items-field-pbfes-paragraphs{
			justify-content:center;
		}
	} 

	.paragraphs-item-pb-form-edu-set{
		padding-left: 0px;
		padding-right: 0px;
		>.content{
			>.field-name-field-title{
				font-size: 20px;
				margin-bottom: 37px;
			}
		}
	}
	
	.paragraphs-item-pb-form-edu{
		width: 100%;
		max-width: 584px;
		margin-bottom: 10px;
		.field-name-field-image{
			.field-item{
				background-size: cover;
			}
		} 
		.field-name-field-image-2{
			.field-item{
				background-size: cover;
			}
		}
		.group-wrap{
			padding: 0 10px;
			.field-name-field-description{
				p{
					br{
						display: none;
					}
				}
			}
		}
	}

}