#front-slider{

}

.front-grey-group{
	display: flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:space-between;
	margin-top: -190px;
	position: relative;
	z-index: 30;
	margin-bottom: 76px;
	@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
		margin-top: 35px;
	} 
	@media all and (min-width: 1201px) and (max-width: 1280px) {
		margin-top: 35px;
	}
	&:before,
	&:after{
		content: none;
	}
}

.paragraphs-item-pb-grey-block{
	background-color: rgba(79,94,125,.82);
	width: 388px;
	height: 150px;
	position: relative;
	margin-bottom: 5px;
	> .content{
		display: flex;
		flex-direction:row;
		align-items:center;
		height: 100%;
	}
	.field-name-field-image{
		margin-right: 20px;
		border-right: 1px solid #fdb52d;
		.field-item{
			width: 115px;
			height: 90px;
			background-position: center;
		}
	}
	.group-right{
		padding-top: 3px;
	}
	.field-name-field-title{
		color: white;
		font-family: $MontserratAlter;
		font-size: 18px;
		font-weight: 700;
		line-height: 1.68;
		text-transform: uppercase;
		margin-bottom: 9px;
	}
	.field-name-field-description{
		color: white;
		font-size: 15px;
		font-weight: 300;
		line-height: 1.2;
		.field-item{
			> *{
				&:last-child{
					&:after{
						content: '';
						padding-left: 16px;
						background: url(../images/yellow-arr.png) right center no-repeat;
					}
				}
			}
		}
	}
	.field-name-field-link{
		a{
			display: block;
			font-size: 0;
			text-indent: -9999px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
	}
}

.front-intermediate-1{
	line-height: 1.6;
	font-size: 17px;
	margin-bottom: 63px;
}

.paragraphs-item-pb-set-properties{
	margin-bottom: 8px;
	>.content{

	}
	.field-name-field-title{
		color: #384359;
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 64px;
		padding-left: 256px;
	}
	.field-name-field-subtitle{
		color: #384359;
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 30px;
		text-align: center;
		padding-left: 91px;
	}

}

.paragraphs-item-pb-title-text{
	position: relative;
	> .content{
		display: flex;
		flex-direction:row;
		align-items:center;
		padding: 91px 0 90px 0;
	}
	.field-name-field-title{
		color: #384359;
		font-family: $Lora;
		font-size: 30px;
		font-weight: 400;
		text-transform: uppercase;
		width: 330px;
		margin-right: 58px;
		border-right: 5px solid #fdb52d;
		padding: 27px 17px 27px 0;
		line-height: 1.2;
		text-align: right;
		background: url(../images/setproperties__top-border.png) right 0 top 0 no-repeat,url(../images/setproperties__bottom-border.png) right 0 bottom 0 no-repeat;
		min-width: 150px;
		*{
			margin: 0;
		}
	}
	.field-name-field-description{
		color: #384359;
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		width: calc(100% - 388px);
		p{
			margin: 0;
			&:not(:last-child){
				margin-bottom: 10px;
			}
		}
	}
	&:nth-child(2n){
		background-color: #ecf1f3;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: -300%;
			width: 300%;
			height: 100%;
			background-color: #ecf1f3;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: -300%;
			width: 300%;
			height: 100%;
			background-color: #ecf1f3;
		}
	}
	&:first-child{
		>.content{
			padding: 57px 0 86px 0;
		}
	}
}

.paragraphs-item-pb-content-background{
	position: relative;
	>.content{

	}
	.field-name-field-image{
		.field-item{
			background-size: cover;
		}
	}
	.group-container{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 3;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.field-name-field-description{

	}
}

.front-number-complex{
	margin-bottom: 10px;
	.title{
		color: white;
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 30px;
		margin-top: 142px;
	    text-align: center;
	    padding-right: 94px;
	}
	.subtitle{
		color: white;
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 64px;
		text-align: center;
		padding-left: 228px;
	}
	.numbers{
		display: flex;
		flex-direction:row;
		justify-content:space-between;
		margin-top: 52px;
		.number{
			display: flex;
			flex-direction:row;
			align-items:center;
			.n1{
				color: white;
				font-family: $Lora;
				font-size: 84px;
				font-weight: 400;
				line-height: 1;
				padding: 19px 9px 18px 0px;
				margin-right: 19px;
				border-right: 5px solid #fdb52d;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					top:0;
					right: 0;
					width: 66px;
					height: 14px;
					border-top: 5px solid #fdb52d;
					border-left: 5px solid #fdb52d;
				}
				&:after{
					content: '';
					position: absolute;
					bottom:0;
					right: 0;
					width: 66px;
					height: 14px;
					border-bottom: 5px solid #fdb52d;
					border-left: 5px solid #fdb52d;
				}
			}
			.n2{
				color: white;
				font-family: $MontserratAlter;
				font-size: 18px;
				font-weight: 700;
				line-height: 1.34;
				text-transform: uppercase;
			}
		}
	}
}

.page-node-168,
.page-node-382,
.page-node-259,
.page-node-386,
.page-node-155,
.page-node-391,
.page-node-178,
.page-node-394,
.page-node-179,
.page-node-395 {
	.paragraphs-item-pb-slider-gallery{
		.slider-for{
			&:before{ 
				background: url(../images/lifeschool2.png) center no-repeat;
			}
		}
	}
}

.i18n-ru { 
	&.page-node-168,
	&.page-node-382,
	&.page-node-259,
	&.page-node-386,
	&.page-node-155,
	&.page-node-391,
	&.page-node-178,
	&.page-node-394,
	&.page-node-179,
	&.page-node-395 {
		.paragraphs-item-pb-slider-gallery{
			.slider-for{
				&:before{ 
					background: url(../images/lifeschool2-ru.png) center no-repeat;
				}
			}
		}
	}
}

.paragraphs-item-pb-slider-gallery{
	
	.slider-for{
		margin-bottom: 10px;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 230px;
			height: 230px;
			background: url(../images/lifeschool.png) center no-repeat;
			z-index: 2;
		}
		.paragraphs-item-pb-slide-gallery{
			> .content{
				position: relative;
				width: calc(100% - 5px);
			}
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.field-name-field-description{
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 28px 0 27px 0;
				background-color: rgba(56,67,89,.7);
				color: white;
				font-family: $MontserratAlter;
				font-size: 25px;
				font-weight: 700;
				line-height: 1;
				text-align: center;
				p{
					margin: 0;
				}
			}
			&.right,
			&.slick-active:nth-child(2){
				> .content{
					margin-left: auto;
				}
			}
		}
		.slick-prev{
			left: 40px;
			background-image: url('../images/slidernav__left.png');
			background-position: center;
			background-repeat: no-repeat;
		}
		.slick-next{
			right: 40px;
			background-image: url('../images/slidernav__right.png');
			background-position: center;
			background-repeat: no-repeat;
		}
		.slick-arrow{
			position: absolute;
			top: 50%;
			transform:translateY(-50%);
			font-size: 0px;
			text-indent: -9999px;
			width: 40px;
			height: 40px;
			background-color: #46bceb;
			z-index: 5;
			border:0;
		}
	}
	
	.slider-nav{
		margin-bottom: 10px;
		.paragraphs-item-pb-slide-gallery{
			.field-name-field-image{
				.field-item{
					max-width: calc(100% - 10px);
					background-size: cover;
					margin-left: auto;
					margin-right: auto;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.field-name-field-description {
				display: none;
			}
		}
	}

}

.i18n-ru {
	.paragraphs-item-pb-slider-gallery{
		.slider-for{
			&:before{
				background: url(../images/lifeschool-ru.png) center no-repeat;
			}
		}
	}
}

.front-news{
	background-color: #ecf1f3;
	position: relative;
	padding: 110px 0 130px 0;
	.news-title{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 64px;
		text-align: center;
		padding-right: 114px;
	}
	.news-subtitle{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 30px;
		text-align: center;
		padding-left: 122px;
		margin-top: 1px;
	}
	.all-news{
		text-align: center;
		a{
			color: #384359;
			font-family: $Lora;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
		}
	}
	.news-insert{
		margin-top: 56px;
		margin-bottom: 19px;
	}
}

.paragraphs-item-pb-ins-news{
	.news-title{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 64px;
		text-align: center;
		padding-right: 114px;
	}
	.news-subtitle{
		font-family: $Lora;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 30px;
		text-align: center;
		padding-left: 122px;
		margin-top: 1px;
	}
	.all-news{
		text-align: center;
		a{
			color: #384359;
			font-family: $Lora;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
		}
	}
	.news-insert{
		margin-top: 56px;
		margin-bottom: 19px;
	}
}

.font-last-word{
	font-size: 17px;
	font-weight: 300;
	line-height: 1.6;
	padding: 113px 0 111px 0;
	text-align: center;
	p{
		margin: 0;
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}
}

@include respond-to('medium'){
	.front-grey-group{
		width: 768px;
		margin-top: 0px;
		margin-bottom: 48px;
	}
	.paragraphs-item-pb-grey-block{
		width: 381px;
		margin-bottom: 3px;
	}
	.front-intermediate-1{
		margin-bottom: 78px;
		br{
			display: none;
		}
	}

	.paragraphs-item-pb-set-properties{
		> .content{
			> .field-name-field-title{
				padding-left: 0px;
				text-align: center;
				font-size: 40px;
				margin-bottom: 12px;
			}
			> .field-name-field-subtitle{
				padding-left: 0px;
				font-size: 20px;
			}
		}
	} 
	
	.paragraphs-item-pb-title-text{
		> .content{
			padding: 57px 0 53px 0;
		}
		.field-name-field-title{
			width: 228px;
			margin-right: 28px;
			font-size: 20px;
			padding: 43px 20px 46px 0;
		}
		.field-name-field-description{
			width: calc(100% - 260px);
			line-height: 1.36;
		}
		&:first-child{
			>.content{
				padding: 51px 0 56px 0;
			}
		}
	}

	.front-number-complex{
		.numbers{
			//justify-content:center;
			display: block;
			/*.number-1,
			.number-3{
				display: none;
			}*/
			.number{
				flex-direction:column;
				.n1{
					margin-bottom: 26px;
				}
				.n2{
					text-align: center;
				}
			}
		} 
	} 
	
	.paragraphs-item-pb-slider-gallery{
		margin-bottom: 111px;
		.slider-nav{
			display: none;
		}

		.slider-for{
			padding-top: 145px;
			&:before{
				top: 30px;
				bottom: auto;
				margin: 0 auto;
			}
			.paragraphs-item-pb-slide-gallery{
				>.content{
					width: 100%;
					margin: 0;
				}
				.field-name-field-description{
					font-size: 17px;
					padding: 35px 0 28px 0;
				}
			} 
			.slick-arrow{
				margin-top: 73px;
			}
			.slick-prev{
				left: 0;
			}
			.slick-next{
				right: 0;
			}
		}
	} 
	
	.news-insert{
		.view-content{
			.views-row{
				width: 358px!important;
				display: none;
				&:nth-child(1),
				&:nth-child(2){
					display: block;
				}
			}
		}
	}
	
	.font-last-word{
		padding: 86px 0 84px 0;
		br{
			display: none;
		}
		.container{
			padding-left: 40px;
			padding-right: 40px;
		}
	}

}

@include respond-to('small'){
	.front-grey-group{
		margin-top: 0px;
		margin-bottom: 54px;
		padding-left: 0px!important;
		padding-right: 0px!important;
		justify-content:center;
	}
	.paragraphs-item-pb-grey-block{
		max-width: 388px;
		width: 100%;
		margin-bottom: 3px;
		margin-bottom: 1px;
		.field-name-field-image{
			.field-items{
				width: 84px;
			    height: 90px;
			    display: flex;
			    flex-direction: row;
			    justify-content: center;
			    align-items: center;
				.field-item{
					width: initial;
				    height: initial;
					background-size: 80%;
				}
			}
		} 
		.group-right{
			padding-top: 9px;
			.field-name-field-title{
				font-size: 14px;
				margin-bottom: 11px;
			}
			.field-name-field-description{
				font-size: 14px;
				line-height: 1.3;
			}
		}
	}

	.front-intermediate-1{
		margin-bottom: 102px;
		line-height: 1.42;
		br{
			display: none;
		}
	}

	.paragraphs-item-pb-set-properties{
		> .content{
			> .field-name-field-title{
				padding-left: 0px;
				text-align: center;
				font-size: 40px;
				margin-bottom: 0px;
			}
			> .field-name-field-subtitle{
				padding-left: 0px;
				font-size: 20px;
				padding: 0 20px;
				line-height: 1.2;
			}
		}
	} 
	
	.paragraphs-item-pb-title-text{
		> .content{
			padding: 59px 0 53px 0;
			flex-direction:column;
		}
		.field-name-field-title{
			width: initial;
		    margin-right: 0px;
		    font-size: 24px;
		    padding: 40px 20px 35px 0;
		    max-width: 270px;
		    line-height: 1.24;
		    margin-bottom: 33px;
		}
		.field-name-field-description{
			width: initial;
			line-height: 1.36;
			text-align: center;
			p{
				br{
					display: none;
				}
			}
		}
		&:first-child{
			>.content{
				padding: 47px 0 54px 0;
			}
		}
	}

	.front-number-complex{
		.title{
			padding-right: 0px;
		    font-size: 20px;
		    margin-top: 115px;
		    line-height: 1.2;
		    margin-bottom: 9px;
		}
		.subtitle{
			padding-left: 0px;
			font-size: 40px;
		}
		.numbers{
			//justify-content:center;
			margin-top: 62px;
			display: block;
			/*.number-1,
			.number-3{
				display: none;
			}*/
			.number{
				flex-direction:column;
				.n1{
					margin-bottom: 26px;
				}
				.n2{
					text-align: center;
				}
			}
		} 
	} 
	
	.paragraphs-item-pb-slider-gallery{
		margin-bottom: 0px;
		.slider-nav{
			display: none;
		}

		.slider-for{
			padding-top: 239px;
			margin-bottom: 0px;
			&:before{
				top: 20px;
				bottom: auto;
				margin: 0 auto;
			}
			.paragraphs-item-pb-slide-gallery{
				>.content{
					width: 100%;
					margin: 0;
				}
				.field-name-field-description{
					font-size: 15px;
				    line-height: 1.2;
				    padding: 25px 0 20px 0;
				    position: static;
				}
			} 
			.slick-arrow{
				margin-top: 73px;
			}
			.slick-prev{
				left: 0;
			}
			.slick-next{
				right: 0;
			}
		}
	} 
	
	.front-news{
		padding: 115px 0 119px 0;
	}

	.paragraphs-item-pb-ins-news{
		padding-left: 0px!important;
		padding-right: 0px!important;
		.news-title{
			padding-right: 0;
			font-size: 40px;
		}
		.news-subtitle{
			padding-left: 0px;
			font-size: 20px;
		}

		.news-insert{
			margin-top: 37px;
			margin-bottom: 0px;
			.view-content{
				justify-content:center;
				.views-row{
					width: 100%!important;
					max-width: 364px;
					&:not(:first-child){
						display: none;
					}
					.node-m-news{
						.title-and-body{
							padding: 56px 16px 0 16px;
							height: 352px;
							text-align: center;
							.node-title{
								margin-bottom: 28px;
								padding: 0 27px;
							}
							.field-name-body{
								line-height: 1.4;
							}
						}
					} 
				}
			}
		}

	} 
	
	.font-last-word{
		padding: 85px 0 56px 0;
		line-height: 1.4;
		br{
			display: none;
		}
		.container{
		}
	}
}