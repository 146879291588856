.node-type-m-news{
	
	#main-wrapper{
		background: url(../images/nodes__bg.jpg) center top / cover no-repeat;
	}

	.node-m-news{
		padding: 152px 0 111px 0;
		.node__container{

		}
		.field-name-field-image{
			float: left;
			margin: 0px 69px 3px 0;
			.field-item{
				border-bottom:2px solid #fdb52d;
				img{
					max-width: 100%;
					height: auto;
				}
			}
		}
		.date-and-title{
			font-family: $Lora;
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 34px;
			margin-top: 4px;
			.date{
				padding-right: 10px;
			}
			.title{
				color: #eba327;
			}
		}
		.field-name-body{
			font-size: 17px;
			font-weight: 300;
			line-height: 1.6;
			img {
				max-width: 100%;
				height: auto;
			}
			p{
				margin-bottom: 27px;
			}
		}
	}

	@include respond-to('medium'){
		.node-m-news{
			padding-top: 76px;
			.field-name-field-image{
				margin: 0px 40px 4px 0;
			}
		} 
	}
	@include respond-to('small'){
		.node-m-news{
			padding: 80px 0 80px 0;
			.field-name-field-image{
				margin: 0px 0px 25px 0;
				float: none;
			}
			.field-name-body{
				line-height: 1.4;
				font-size: 16px;
			}
		} 
	}

}

body.page-news{
	#main-wrapper{
		background: #ecf1f3;
	}
	
	.title-wrap{
		margin-top: 147px;
		padding: 0 0 22px 0;
		border-bottom: 2px solid #fdb52d;
		h1.page-title{
			margin: 0 0 0 0;
			line-height: 1;
		}
		.title{
			color: #384359;
			font-family: $Lora;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			font-size: 30px;
		}
		.subtitle{
			color: #384359;
			font-family: $Lora;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			font-size: 64px;
			padding-left: 25px;
		}
	}

}

@include respond-to('medium'){
	body.page-news{
		.title-wrap{
			margin-top: 110px;
		}
	} 
}

@include respond-to('small'){
	body.page-news{
		.title-wrap{
			margin-top: 90px;
			.title{
				text-align: center;
				font-size: 20px;
			}
			.subtitle{
				font-size: 40px;
				text-align: center;
				padding-left: 0px;
			}
		}
	} 
}

.view-v-news{
	.view-filters{
		margin-bottom: 132px;
		.form-control.jq-selectbox{
			margin-left: auto;
		}
	}
	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		.views-row{
			width: 364px;
			margin-bottom: 60px;
			&:hover{
				box-shadow: 0 22px 44px rgba(0, 0, 0, 0.12);
				.node-m-news{
					.field-name-field-image{
						border-color: #ef4665;
					}
					.title-and-body{
						.node-title{
							a{
								color: #ef4665;
							}
						}
						.field-name-body{
							a.more-link{
								color: #ef4665;
							}
						}
					}
				}
			}
			.node-m-news{
				.field-name-field-image{
					border-bottom: 2px solid #fdb52d;
					a{
						display: block;
					}
					.field-item{
						background: none!important;
						img{
							max-width: 100%;
							height: auto;
						}
					}
				}
				.title-and-body{
					background: #ffffff;
					padding: 56px 47px 0 47px;
					height: 372px;
					.node-title{
						margin-bottom: 26px;
						a{
							color: #325da4;
							font-family: $Lora;
							font-size: 20px;
							font-weight: 400;
							text-transform: uppercase;
							line-height: 1.2;
						}
					}
					.field-name-body{
						font-size: 17px;
						line-height: 1.6;
						font-weight: 300;
						a.more-link{
							color: #fdb52d;
							font-family:$Lora;
							font-weight: 400;
							//padding-left: 15px;
							display: block;
						}
					}
				}
			}
		}
	}
}

.news-inner{
	padding: 0 0 60px 0;
	margin-top: -82px;
}

@include respond-to('medium'){
	.view-v-news{
		.view-content{
			.views-row{
				width: 358px;
			}
		} 
	} 
}
@include respond-to('small'){
	.view-v-news{
		.view-filters{
			margin-bottom: 85px;
			.form-control.jq-selectbox{
				margin-right: auto;
			}
		}
		.view-content{
			.views-row{
				width: 100%;
				max-width: 364px;
				.node-m-news{
					.title-and-body{
						padding: 56px 16px 0 16px;
						height: 352px;
						text-align: center;
						.node-title{
							margin-bottom: 28px;
							padding: 0 27px;
						}
						.field-name-body{
							line-height: 1.4;
						}
					}
				} 
			}
		} 
	} 

	.news-inner{
		padding-left: 0px!important;
		padding-right: 0px!important;
		margin-top: 45px;
	}
}