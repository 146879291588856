body.node-type-m-yaslya{

	.node-m-yaslya{
		
		.big-image{
			margin-bottom: 119px;
			.field-name-field-image{
				.field-item{
					background-size: cover;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.bi__container{
				display: flex;
				flex-direction:column;
				align-items:flex-start;
				justify-content:flex-end;
				height: 100%;
				@include respond-to('large'){
					padding-bottom: 90px;
				}
				@media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
					padding-bottom: 35px;
				}
				@media all and (min-width: 1201px) and (max-width: 1280px) {
					padding-bottom: 35px;
				}
				.node-title{
					color: white;
					font-family: $Lora;
					font-size: 40px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: rgba(235,163,39,0.9);
					padding: 15px 60px 27px 59px;
				}
				.field-name-body{
					font-family: $MontserratAlter;
					font-size: 18px;
					font-weight: 400;
					background-color: rgba(255,255,255,0.9);
					padding: 18px 38px 10px 39px;
					line-height: 1.24;
					margin-left: 30px;
					margin-top: -10px;
					//margin-bottom: 90px;
				}
			}
		}

	}
	
	.paragraphs-item-pb-set-properties{
		margin-bottom: 116px;
		> .content{
			> .field-name-field-title{
				font-size: 30px;
				padding-right: 301px;
				padding-left: 0px;
				text-align: center;
			}
			> .field-name-field-subtitle{
				padding-left: 86px;
				font-size: 64px;
			}
			
		}
	} 

	@include respond-to('medium'){
		.node-m-yaslya{
			.big-image{
				margin-bottom: 80px;
				.bi__container{
					position: relative;
					width: 100%;
					.node-title{
						width: 100%;
						text-align: center;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						br{
							display: none;
						}
					}
				}
			} 
		} 
	}

	@include respond-to('small'){
		.node-m-yaslya{
			.big-image{
				margin-bottom: 62px;
				.bi__container{
					position: relative;
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					.node-title{
						width: 100%;
						text-align: center;
						font-size: 20px;
						padding: 17px 58px 14px 58px;
						line-height: 1.2;
					}
					.field-name-body{
						width: 100%;
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
						text-align: center;
						font-size: 16px;
						padding: 24px 10px 10px 10px;
						br{
							display: none;
						}
					}
				}
			} 
			
			.paragraphs-item-pb-circle-text-set{
				padding-bottom: 30px;
				.field-name-field-title{
					font-size: 20px;
					margin-bottom: 37px;
				}
			} 

			.paragraphs-item-pb-set-properties{
				>.content{
					>.field-name-field-title{
						font-size: 40px;
						padding-left: 0px;
						padding-right: 0px;
					}
					>.field-name-field-subtitle{
						font-size: 20px;
						padding: 0 20px;
					}
				}
			}
			
			.font-last-word{
				padding: 85px 0 56px 0;
			}

		} 
	}

}

.paragraphs-item-pb-circle-text-set{
	border-bottom: 2px solid #fdb52d;
	padding-bottom: 34px;
	margin-bottom: 87px;
	.field-name-field-title{
		font-family: $Lora;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.17;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 43px;
	}
	.paragraphs-items-field-pbcts-paragraphs{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content: center;
	}
}

.paragraphs-item-pb-circle-text{
	width: 25%;
	text-align: center;
	margin-bottom: 45px;
	padding: 0px 10px;
	> .content{

	}
	.field-name-field-pbct-circle{
		font-size: 0;
		margin-bottom: 27px;
		.field-item{
			display: inline-block;
			background-position: center;
			width: 97px;
			height: 97px;
			border-radius: 100%;
		}
	}
	.field-name-field-description{
		font-family: $Lora;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.2;
		.ct__title{
			font-family: $Lora;
			font-size: 16px;
			font-weight: 700;
			min-height: 33px;
		    margin-bottom: 11px;
		    line-height: 1.2;
		}
		a{
			color: #ef4665;
			text-decoration: underline;
		}
	}
	&:nth-child(1){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #ef4665;
			}
		}
	}
	&:nth-child(2){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #eba327;
			}
		}
	}
	&:nth-child(3){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #384359;
			}
		}
	}
	&:nth-child(4){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #81a051;
			}
		}
	}
	&:nth-child(5){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #384359;
			}
		}
	}
	&:nth-child(6){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #81a051;
			}
		}
	}
	&:nth-child(7){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #ef4665;
			}
		}
	}
	&:nth-child(8){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #eba327;
			}
		}
	}
	&:nth-child(9){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #ef4665;
			}
		}
	}
	&:nth-child(10){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #eba327;
			}
		}
	}
	&:nth-child(11){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #384359;
			}
		}
	}
	&:nth-child(12){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #81a051;
			}
		}
	}
	&:nth-child(13){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #384359;
			}
		}
	}
	&:nth-child(14){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #81a051;
			}
		}
	}
	&:nth-child(15){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #ef4665;
			}
		}
	}
	&:nth-child(16){
		.field-name-field-pbct-circle{
			.field-item{
				background-color: #eba327;
			}
		}
	}
}

@include respond-to('medium'){
	.paragraphs-item-pb-circle-text{
		width: 50%;
		.field-name-field-description{
			padding: 0 10px;
		}
	}
}
@include respond-to('small'){
	.paragraphs-item-pb-circle-text{
		width: 100%;
		margin-bottom: 46px;
		.field-name-field-description{
			padding: 0 19px;
		}
	}
}

.how-introduce{
	.field-name-field-image{
		.field-item{
			background-size:cover;
		}
	}
	.field-name-field-description{
		/*padding-top: 113px;*/
	}
	font-size: 17px;
	line-height: 1.6;
	color: white;
	font-weight: 300;
	text-align: center;
	span{
		color: #fdb52d;
	}
	.title{
		color: white;
		font-family: $Lora;
		font-size: 64px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	a.ctools-use-modal{
		margin-top: 33px;
		color: white;
		font-family: $MontserratAlter;
		font-size: 14px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		padding: 19px 23px 19px 23px;
		background-color: #fdb52d;
		border-radius: 25px;
		display: inline-block;
	}
}

@include respond-to('medium'){
	.how-introduce{
		br{
			display: none;
		}
		strong{
			padding-left: 5px;
		}
	}
}
@include respond-to('small'){
	.how-introduce{
		.title{
			font-size: 40px;
		}
		.field-name-field-image{
			.field-item{
				background-size: cover;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.group-container{
			position: relative;
			.field-name-field-description{
				padding-top: 88px;
				padding-bottom: 88px;
				p{
					strong{
						padding: 0 32px;
						display: block;
					}
				}
			}
		}
		a.ctools-use-modal{
			border-radius: 25px;
		}
	}
}