body.node-type-m-kontacts{
	
	#main-wrapper{
		background: url(../images/nodes__bg.jpg) center top / cover no-repeat;
	}

	.node-m-kontacts{
		padding: 146px 0 90px 0;
		.field-name-body{
			margin-bottom: 46px;
		}
		.phones_contact {
			background: url(../images/phones_contact.png) no-repeat;
			padding: 15px 0px;
			padding-left: 59px;
		}
		.title-wrap{
			padding-bottom: 21px;
			border-bottom: 2px solid #fdb52d;
			font-family: $Lora;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 86px;
			.title{
				font-size: 30px;
				padding-right: 313px;
			}
			.subtitle{
				font-size: 64px;
				padding-left: 40px;
				margin-top: 2px;
			}
		}
		p{
			font-size: 17px;
			line-height: 1.3;
			text-align: center;
			margin: 0;
			&:not(:last-child){
				margin-bottom: 22px;
			}
			b{
				font-weight: 700;
			}
			a{
				&.tel{
					color: #384359;
				}
				&:not(.tel){
					color: #eba327;
					font-weight: 300;
					text-decoration: underline;
				}
			}
		}

		#block-menu-menu-social-menu{
			.block__content{
				ul{
					margin: 0 0 0 0;
					padding: 0 0 0 0;
					font-size: 0;
					display: flex;
					flex-direction:row;
					justify-content:center;
					li{
						list-style: none;
						background: none!important;
						display: inline-block;
						&:not(:last-child){
							margin-right: 10px;
						}
						a{
							padding: 0;
							font-size: 0;
							text-indent: -9999px;
							display: flex;
							flex-direction:row;
							justify-content:center;
							align-items:center;
							width: 50px;
							height: 50px;
							background-color: #eba327;
						}
					}
				}
			}
		}

	}
	

}


@include respond-to('medium'){
	body.node-type-m-kontacts{
		.node-m-kontacts{
			padding: 110px 0 80px 0;
		}
	} 
}
@include respond-to('small'){
	body.node-type-m-kontacts{
		.node-m-kontacts{
			padding: 90px 0 70px 0;
			.title-wrap{
				margin-bottom: 50px;
				.title{
					font-size: 20px;
					padding-right: 0px;
				}
				.subtitle{
					font-size: 40px;
					padding-left: 0px;
					text-align: center;
				}
			} 
		}
	} 
}