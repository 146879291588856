.paragraphs-item-pb-nivo-slider{
	.field-name-field-image{
		display: none;
		.field-item{
			background: none!important;
		}
	}
	.group-wrap{
		position: absolute;
		//top: 389px;
		bottom: 226px;
	    left: calc((100% - #{$container-lg}) / 2);
	    z-index: 100;
	    display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media all and (min-height: 550px) and (max-height: 720px) and (min-width: 1200px) {
        	bottom: 35px;
        }
        @media all and (min-width: 1201px) and (max-width: 1280px) {
			bottom: 35px;
		}
	}
	.field-name-field-title{
		color: white;
		font-family: $Lora;
		font-size: 40px;
		font-weight: 400;
		text-transform: uppercase;
		background-color: rgba(235,163,39,.9);
		padding: 15px 60px 27px 59px;
	    opacity: 0;
	    transition: opacity 0.5s linear;
	}
	.field-name-field-description{
		color: #384359;
		font-family: $MontserratAlter;
		font-size: 20px;
		font-weight: 700;
		background-color: rgba(255,255,255,.9);
		padding: 22px 47px 12px 48px;
		line-height: 1.24;
	    opacity: 0;
	    transition: opacity 0.5s linear;
	    margin-left: 30px;
	    margin-top: -10px;
	}
	&.fade-type{
		.field-name-field-title{
			opacity: 1;
		}
		.field-name-field-description{
			opacity: 1;
		}
	}
}

.nivoSlider{
	.nivo-main-image{
		max-width: 100%!important;
		height: auto!important;
	}
}

.nivo-directionNav{
	.nivo-prevNav,
	.nivo-nextNav{
		position: absolute;
		top: 38.2%;
		background-color:rgba(56,67,89,.5);
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-position: center;
		line-height: 1;
		font-size: 0;
		text-indent: -9999px;
		display: block;
		&:hover{
			background-color:rgba(56,67,89,1);
		}
	}
	.nivo-prevNav{
		background-image: url('../images/slidernav__left.png');
		left: 40px;
	}
	.nivo-nextNav{
		background-image: url('../images/slidernav__right.png');
		right: 40px;
	}
}

@include respond-to('medium'){
	
	.paragraphs-item-pb-nivo-slider{
		.group-wrap{
			top: auto;
			bottom: 0px;
			left: 0px;
			align-items:center;
			text-align: center;
			width: 100%;
			> *{
				width: 100%;
			}
			.field-name-field-title{
				font-size: 30px;
				padding: 31px 60px 27px 59px;
			}
			.field-name-field-description{
				margin-top: 0px;
				margin-left: 0px;
				padding: 32px 47px 25px 48px;
			}		
		}
	} 

	.nivo-directionNav{
		
		.nivo-prevNav,
		.nivo-nextNav{
			//top: 26.2%;
			top: 152px;
		}
		
		.nivo-prevNav{
			left: 0;
		}

		.nivo-nextNav{
			right: 0;
		}

	} 

}

@include respond-to('small'){
	
	.paragraphs-item-pb-nivo-slider{
		.group-wrap{
			top: auto;
			bottom: 0px;
			left: 0px;
			align-items:center;
			text-align: center;
			width: 100%;
			> *{
				width: 100%;
			}
			.field-name-field-title{
				font-size: 20px;
				padding: 18px 10px 13px 10px;
				line-height: 1.2;
			}
			.field-name-field-description{
				margin-top: 0px;
				margin-left: 0px;
				padding: 28px 19px 21px 19px;
				font-size: 16px;
			}		
		}
	} 

	.nivo-directionNav{
		display: none;
		.nivo-prevNav,
		.nivo-nextNav{
			top: 26.2%;
		}
		
		.nivo-prevNav{
			left: 0;
		}

		.nivo-nextNav{
			right: 0;
		}

	} 

}