#header{
	position: relative;
	z-index: 19;
	#logotype{
		position: absolute;
		top: 30px;
	    left: calc((100% - #{$container-lg}) / 2);
	    margin-left: -28px;
		width: 387px;
		height: 141px;
		background-color: white;
		box-shadow: 0 7px 7px rgba(0, 0, 0, 0.07);
		display: flex;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		.wrap-link{
			font-size: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
		.img-part{
			margin-left: 30px;
			img{

			}
		}
		.text-part{
			width: 251px;
			height: 100%;
			display: flex;
			flex-direction:row;
			align-items:center;
			justify-content: center;
			margin-left: auto;
			background-color: #4f5e7d;
			color: white;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1.24;
			padding-top: 10px;
		}
	}
	
	.header__top{
		background-color: #384359;
		> .h__container{
			padding-left: 427px;
			height: 50px;
			display: flex;
			flex-direction:row;
			align-items:center;
			justify-content:flex-start;
		}
		#block-locale-language{
			margin-right: 68px;
			ul.language-switcher-locale-url{
				display: flex;
				flex-direction:row;
				padding: 0;
				margin: 0;
				li{
					&.ru {
						display: none;
					}
					list-style: none;
					&:not(:last-child){
						margin-right: 3px;
					}
					a{
						display: flex;
						flex-direction:row;
						align-items:center;
						justify-content:center;
						border-radius: 100%;
						width: 23px;
						height: 22px;
						background-color: white;
						color: #384359;
						font-family: $MontserratAlter;
						font-size: 11px;
						font-weight: 700;
						text-transform: uppercase;
					}
					&.active,
					&:hover{
						a{
							background-color: #ef4665;
							color: white;
						}
					}
				}
			}
		}
		#block-block-1{
			margin-right: 40px;
			.telephones{
				display: flex;
				flex-direction:row;
				.tel{
					display: flex;
					flex-direction:row;
					align-items:center;
					justify-content:flex-start;
					font-family: $Lora;
					font-size: 14px;
					font-weight: 400;
					text-transform: uppercase;
					color: white;
					a{
						color: white;
					}
					span{
						color: #eba327;
					}
					img{
						margin-right: 10px;
					}
					&:not(:last-child){
						margin-right: 40px;
					}
				}
			}
		}
		#block-menu-menu-social-menu{
			ul.menu{
				display: flex;
				flex-direction:row;
				align-items:center;
				margin: 0;
				padding: 0;
				li{
					list-style: none;
					background-image:none!important;
					width: 40px;
					border-left: 1px solid #eba327;
					min-height: 21px;
					display: flex;
					flex-direction:row;
					align-items: center;
					justify-content: center;
					&:last-child{
						border-right: 1px solid #eba327;
					}
					a{
						font-size: 0;
						display: block;
						padding: 0;
						background:none;
						&:hover{
							background:none;
						}
						img{

						}
					}
				}
			}
		}
		#block-multiblock-1{
			margin-left: auto;
			a.ctools-use-modal{
				display: flex;
				flex-direction:row;
				align-items:center;
				justify-content:center;
				text-align: center;
				width: 150px;
				height: 50px;
				background-color: #ef4665;
				color: white;
				font-family: $MontserratAlter;
				font-size: 14px;
				font-weight: 700;
				line-height: 1;
				text-transform: uppercase;
			}
		}
	}

	.header__menu{
		background-color: #ffffff;
		> .h__container{
			padding-left: 427px;
			padding-top: 43px;
			height: 70px;
		}
		#block-system-main-menu{
			> .block__content{
				> ul.menu{
					display: flex;
					flex-direction:row;
					padding: 0;
					margin: 0;
					> li{
						list-style: none;
						position: relative;
						&:not(:last-child){
							margin-right: 40px;
						}
						> a, > span{
							padding: 0;
							display: block;
							color: #384359;
							font-family: $MontserratAlter;
							font-size: 17px;
							font-weight: 700;
							line-height: 1;
							position: relative;
							letter-spacing: 0.7px;
							cursor: pointer;
							.caret{
								display: none;
							}
							&:hover{
								background: none;
							}
							&.active,
							&:hover{
								&:after{
									content: '';
									position: absolute;
									bottom: -14px;
									left: -5px;
									width: calc(100% + 10px);
									height: 8px;
									background-color: #eba327;
									z-index: 10;
								}
							}
						}
						> ul{
							display: none;
							width: 300px;
							position: absolute;
							left: -5px;
							top: 100%;
							background-color: rgba(255, 255, 255, 0.95);
							box-shadow: 0 22px 44px rgba(0, 0, 0, 0.12);
							padding: 43px 30px 43px 30px;
							> li{
								list-style: none;
								&:not(:last-child){
									margin-bottom: 18px;
								}
								> a{
									color: #384359;
									font-family: $MontserratAlter;
									font-size: 14px;
									font-weight: 400;
									line-height: 1.44;
									padding: 0;
									display: block;
									background: none;
									&:hover{
										color: #eba327;
										background: none;
									}
								}
							}
						}
						&:hover{
							> a , > span{
								&:after{
									content: '';
									position: absolute;
									bottom: -14px;
									left: -5px;
									width: calc(100% + 10px);
									height: 8px;
									background-color: #eba327;
									z-index: 10;
								}
							}
							> ul{
								display: block;
							}
						}
					}
				}
			}
		}
		#burger{
			display: none;
			width: 52px;
			height: 52px;
			background: url('../images/burger.png') no-repeat;
			position: relative;
			cursor: pointer;
			margin-left: auto;
		}
	}

}

#all-wrapper{
	&.aw-mm{
		right: 0;
		transition:all 0.3s linear;
	}
}

body.mm{
	.mobile-menu{
		right: 0;
	}
	#all-wrapper{
		right: 248px!important;
	}
	#header{
		.header__menu{
			#burger{
				background: url(../images/burger__active.png) no-repeat;
			}
		}
		.header__top{
			#block-multiblock-1{
				display: none;
			}
		} 
	} 
}

.mobile-menu{
	display: flex;
	flex-direction:column;
	align-items:center;
	justify-content:flex-start;
	text-align: center;
	width: 248px;
	height: 100%;
	background-color: #384359;
	position: absolute;
	top: 0;
	right: -248px;
	transition:right 0.3s linear;
	z-index: 999;
	#block-locale-language{
		order:2;
		margin-bottom: 40px;
		ul.language-switcher-locale-url{
			display: flex;
			flex-direction:row;
			padding: 0;
			margin: 0;
			li{
				list-style: none;
				&:not(:last-child){
					margin-right: 13px;
				}
				a{
					display: flex;
					flex-direction:row;
					align-items:center;
					justify-content:center;
					border-radius: 100%;
					width: 23px;
					height: 22px;
					background-color: white;
					color: #384359;
					font-family: $MontserratAlter;
					font-size: 11px;
					font-weight: 700;
					text-transform: uppercase;
				}
				&.active,
				&:hover{
					a{
						background-color: #ef4665;
						color: white;
					}
				}
			}
		}
	}
	#block-block-1{
		display: none;
	}
	#block-menu-menu-social-menu{
		order:4;
		ul.menu{
			display: flex;
			flex-direction:row;
			align-items:center;
			margin: 0;
			padding: 0;
			li{
				list-style: none;
				background-image:none!important;
				width: 40px;
				border-left: 1px solid #eba327;
				min-height: 21px;
				display: flex;
				flex-direction:row;
				align-items: center;
				justify-content: center;
				&:last-child{
					border-right: 1px solid #eba327;
				}
				a{
					font-size: 0;
					display: block;
					padding: 0;
					&:hover{
						background:none;
					}
					img{

					}
				}
			}
		}
	}
	#block-multiblock-1{
		order:1;
		width: 100%;
		margin-bottom: 45px;
		a.ctools-use-modal{
			display: flex;
			flex-direction:row;
			align-items:center;
			justify-content:center;
			text-align: center;
			width: 100%;
			height: 50px;
			background-color: #ef4665;
			color: white;
			font-family: $MontserratAlter;
			font-size: 14px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
		}
	}
	#block-system-main-menu{
		order: 3;
		margin-bottom: 43px;
		> .block__content{
			> ul.menu{
				display: flex;
				flex-direction:column;
				align-items:center;
				justify-content:flex-start;
				padding: 0;
				margin: 0;
				> li{
					list-style: none;
					&:not(:last-child){
						margin-bottom: 23px;
					}
					> a, > span{
						padding: 0;
						display: block;
						color: white;
						font-family: $MontserratAlter;
						font-size: 17px;
						font-weight: 700;
						line-height: 1;
						position: relative;
						letter-spacing: 0.7px;
						cursor: pointer;
						&:hover,
						&:focus{
							background: none;
						}
						.caret{
							display: none;
						}
					}
					> ul{
						display: none;
						flex-direction:column;
						align-items:center;
						justify-content:flex-start;
						padding: 0;
						margin: 23px 0 0 0;
						> li{
							list-style: none;
							&:not(:last-child){
								margin-bottom: 18px;
							}
							> a{
								padding: 0;
								display: block;
								color: white;
								font-family: $MontserratAlter;
								font-size: 15px;
								font-weight: 700;
								line-height: 1;
								position: relative;
								letter-spacing: 0.7px;
								&:hover{
									background: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include respond-to('medium'){
	#header{
		#logotype{
			top: 79px;
			left: calc((100% - #{$container-md}) / 2);
			margin-left: 0;
			width: initial;
			height: initial;
			background-color: transparent;
			box-shadow: none;
			align-items: flex-start;
			.img-part{
				margin-left: 0px;
				img{
					width: 48px;
					height: 56px;
				}
			}
			.text-part{
				width: initial;
				height: initial;
				margin-left: 10px;
				padding: 0px 0 0 0;
				background-color: transparent;
				color: #4f5e7d;
				font-size: 11px;
			}
		}
		
		.header__top{
			position: relative;
			> .h__container{
				padding-left: 0px;
			}
			#block-locale-language{
				display: none;
			}
			#block-block-1{
				.telephones{
					.tel{
						&:not(:last-child){
							margin-right: 19px;
						}
					}
				}
			}
			#block-menu-menu-social-menu{
				margin-left: auto;
				margin-right: 171px;
			}
			#block-multiblock-1{
				//margin-left: 21px;
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.header__menu{
			> .h__container{
				padding-left: 0px;
				height: 110px;
				padding-top: 29px;
			}
			#block-system-main-menu{
				display: none;
			}
			#burger{
				display: block;
			}
		}

	}
}
@include respond-to('small'){
	#header{
		#logotype{
			top: 79px;
			left: 10px;
			margin-left: 0;
			width: initial;
			height: initial;
			background-color: transparent;
			box-shadow: none;
			align-items: flex-start;
			.img-part{
				margin-left: 0px;
				img{
					width: 48px;
					height: 56px;
				}
			}
			.text-part{
				width: initial;
				height: initial;
				margin-left: 10px;
				padding: 0px 0 0 0;
				background-color: transparent;
				color: #4f5e7d;
				font-size: 11px;
			}
		}
		
		.header__top{
			> .h__container{
				padding-left: 10px;
			}
			#block-locale-language{
				display: none;
			}
			#block-block-1{
				margin-right: 0px;
				width: 100%;
				.telephones{
					.tel{
						&:last-child{
							margin-left: auto;
						}
					}
				}
			}
			#block-menu-menu-social-menu{
				display: none;
			}
			#block-multiblock-1{
				display: none;
			}
		}

		.header__menu{
			> .h__container{
				padding-left: 10px;
				height: 110px;
				padding-top: 29px;
			}
			#block-system-main-menu{
				display: none;
			}
			#burger{
				display: block;
			}
		}

	}
}