#footer{
	background-color: #384359;
	> .f__container{
		height: 387px;
	}
	.f__contacts{
		display: flex;
		flex-direction:row;
		margin-top: 77px;
		justify-content: center;
		.fc{
			text-align: center;
			position: relative;
			min-height: 163px;
			padding-top: 4px;
			&:not(:last-child){
				border-right: 1px solid rgba(255,255,255,.5);
			}
			.fc-title{
				color: #fdb52d;
				font-family: $MontserratAlter;
				font-size: 17px;
				font-weight: 700;
				margin-bottom: 18px;
			}
			.fc-row{
				font-family: $Lora;
				font-size: 21px;
				font-weight: 400;
				color: white;
				margin-bottom: 22px;
				a{
					color: white;
				}
				.ctools-use-modal{
					color: white;
					font-family: $MontserratAlter;
					font-size: 11px;
					font-weight: 700;
					line-height: 1;
					text-transform: uppercase;
					background-color: #ef4665;
					border-radius: 20px;
					padding: 14px 19px 14px 19px;
					display: inline-block;
				}
				.personal{
					color: white;
					font-family: $MontserratAlter;
					font-size: 11px;
					font-weight: 700;
					line-height: 1;
					text-transform: uppercase;
					display: inline-block;
					background-color: #fdb52d;
					border-radius: 20px;
					padding: 14px 18px 14px 18px;
					margin: 0 5px 0 0;
				}
			}
			&.fc-1{
				padding-left: 50px;
				padding-right: 49px;
			}
			&.fc-2{
				padding-left: 59px;
				padding-right: 60px;
			}
			&.fc-3{
				padding-left: 31px;
			}
		}
	}
	.copyright{
		color: white;
		font-size: 17px;
		font-weight: 300;
		line-height: 1.6;
		text-align: center;
		margin-top: 67px;
		padding-left: 11px;
		.development{
			font-size: 15px;
			font-weight: 300;
			color: white;
			//display: none;
			a{
				text-decoration: underline;
				color: #fdb52d;
			}
		}
	}
}

@include respond-to('medium'){

	#footer{
		>.f__container{
			height: initial;
			padding-bottom: 41px;
		}
		.f__contacts{
			margin-top: 50px;
			.fc-1,
			.fc-2,
			.fc-3 .fc-title,
			.fc-3 .fc-row:not(:last-child){
				display: none;
			}
			.fc{
				min-height: initial;
			}
			.fc-3{
				padding: 0!important;
				margin: 0 auto 0 auto;
			}
			.buttons-row{
				display: flex;
				flex-direction:column;
				align-items:center;
				.personal{
					margin: 0 0 11px 0!important;
				}
			}
		}
		.copyright{
			margin-top: 8px;
			p{
				margin-bottom: 5px;
			}
			.development{
				display: block;
			}
		}
	} 

}
@include respond-to('small'){
	#footer{
		>.f__container{
			height: initial;
			padding-bottom: 30px;
		}
		.f__contacts{
			margin-top: 61px;
			.fc-1,
			.fc-2,
			.fc-3 .fc-title,
			.fc-3 .fc-row:not(:last-child){
				display: none;
			}
			.fc{
				min-height: initial;
			}
			.fc-3{
				padding: 0!important;
				margin: 0 auto 0 auto;
			}
			.buttons-row{
				display: flex;
				flex-direction:column-reverse;
				align-items:center;
				.personal{
					margin: 11px 0 0px 0!important;
				}
			}
		}
		.copyright{
			margin-top: 26px;
			font-size: 15px;
			padding-left: 0px;
			line-height: 1.3;
			p{
				margin-bottom: 28px;
			}
			.development{
				display: block;
			}
		}
	}
}